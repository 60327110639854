import {
  App,
  StateChangeListener,
  URLOpenListener,
  RestoredListener,
  BackButtonListener,
  AppState
} from '@capacitor/app'

type EventName = 'appStateChange' | 'appUrlOpen' | 'appRestoredResult' | 'backButton'

type ListenerFunction = StateChangeListener | URLOpenListener | RestoredListener | BackButtonListener

interface Callback {
  event: EventName,
  handler: ListenerFunction
}

interface ICapacitorApp {
  addAppListeners: (callbacks: Array<Callback>) => void
  getState: () => Promise<AppState>
}

export default class CapacitorApp implements ICapacitorApp {
  addAppListeners (callbacks: Array<Callback>) {
    App.removeAllListeners()
    callbacks.forEach((callback: Callback) => {
      App.addListener(callback.event as any, callback.handler as any)
    })
  }

  async getState () {
    return await App.getState()
  }
}
